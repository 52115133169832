@import "../../styles/vars.styl"
@import "../../styles/helpers.styl"

$buttonBlock = {
	sizes: {
		m: 32px,
		l: 48px
	},
	colors: {
		blue: $colors.blue,
		yellow: $colors.yellow,
		white: $colors.white-dark,
	},
	hover: {
		blue: $colors.blue-light,
		yellow: $colors.yellow-light,
		white: $colors.white
	},
	defaultColor: 'blue'
}

.button
	display: inline-block
	vertical-align: middle
	font-set: '15-light'
	padding: 0 15px
	border-radius: 18px
	height: $buttonBlock.sizes.m
	line-height: unit(@height - 3, px)
	transition: background-color .15s ease-in-out, border-color .15s ease-in-out, color .15s ease-in-out
	cursor: pointer
	position: relative
	user-select: none
	outline: none
	border: 1px solid
	box-shadow: 0 1px 2px rgba(#000, .16)
	text-align: center
	box-sizing: border-box
	text-decoration: none
	// font-size: 100%
	// top: -1px

	&__text
		margin: 0 7px
		vertical-align: top

	&__icon
		font-size: inherit
		vertical-align: middle

		for $iconPosition in 'left' 'right'
			&_{$iconPosition}
				margin-{opposite-position($iconPosition)}: 7px

	&:active
		top: 1px


	&_full
		width: 100%
		padding: 0

	&_important
		.button__text
			text-transform: uppercase
			font-set: '18-bold'

	&_size
		for $sizeName, $sizeValue in $buttonBlock.sizes
			&_{$sizeName}
				height: $sizeValue
				line-height: unit(@height - 4, px)
				border-radius: unit(@height / 2, px)

	&_icon
		&_only
			padding: 0
			width: $buttonBlock.sizes.m

			&.button_size
				for $sizeName, $sizeValue in $buttonBlock.sizes
					&_{$sizeName}
						width: $sizeValue


	for $color in 'blue' 'yellow' 'white'
		$mod = ($color == $buttonBlock.defaultColor ? '' : '_color_' + $color)
		$bgColor = $buttonBlock.colors[$color]

		&{$mod}
			background: $bgColor
			color: getContrastColor($bgColor)
			border: 1px solid $bgColor
			box-shadow: none
			font-weight: 600

			&:hover
				background: $buttonBlock.hover[$color]
				border-color: $buttonBlock.hover[$color]

	&_transparent
		// Has visible background
		&_part
			background: rgba(#fff, .2)
			color: #fff
			border: 1px solid rgba(#fff, .7)
			box-shadow: none

			&:hover
				background: rgba(#fff, .3)
				border-color: #fff

		// Fully transparent
		&_full
			box-shadow: none
			background: none
			color: #fff
			border: 1px solid rgba(#fff, .3)

			&:hover
				background: none
				border-color: #fff

		&_half
			padding: 0 10px
			box-shadow: none
			background: none
			color: #fff
			border: 1px solid rgba(#fff, .3)
			opacity: 0.6
			font-size: 15px

			&:hover
				opacity: 1
				background: none
				border-color: #fff

	&_disabled
		&
		&:hover
			cursor: default
			opacity: .1
			border: 1px solid rgba(#fff, .3);

	&_sharp
		border-radius: 4px
		padding: 0 12px

	&_filter
		font-size: 15px;

		& .button__text
			position: relative;
			top: -1px;

	&_panel
		font-size: 15px

	// TODO: refactor it, we depend on order here; Collect all buttons and create
	// comprehensive set of possible buttons
	&_size
		&_m
			font-size: 14px;
			padding: 0 5px;
			height: 30px;
		&_xs
			font-size: 13px;
			padding: 0 0;
			height: 23px;
			line-height: 13px;

	// TODO: this is aweful; Should be rewritten using design-system for
	// AVIONERO V2.0 (aka 1.0)
	&_cloneTabsMobile
		font-size: 14px;
		line-height: 14px;
