@import "../../styles/vars.styl"
.mobilePopup
    position: fixed;
    top: 0;
    z-index: 105;
    height: 100%;
    left: 0;
    right: 0;
    display: none;
    background: #41454e;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    &_opened
        display: flex;
        flex-flow: column;

    .main-form__travelers
        padding-top: 0
