/**
 * Returns required styles for passed set
 * !!! All fonts must be inserted with this helper
 * Example: .foo { font-set: '14-light' }
 *
 * @param {string} $set Font styles in '{size}-{weight}' format
 * @param {object} $options
 */
font-set($set, $options = {})
	$setSplit = split('-', $set)
	$setSize = $setSplit[0]
	$setWeight = $setSplit[1]

	if $setSize in $font-size
		for $key, $value in $font-size[$setSize]
			{$key}: $value

	font-weight: $font-weight[$setWeight]


/**
 *	Returns color, depending on the background
 *
 *	Example: .foo { color: getContrastColor(blue) }
 */
getContrastColor(background, lightColor = #fff, darkColor = #000)
	return luminosity( background ) < 0.5 ? lightColor : darkColor
