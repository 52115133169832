@import "../../styles/vars.styl"
for $value in $offsets.values
	.padding
		&_{$value}
			padding: unit($value, px) !important

		&_horizontal_{$value}
			padding-left: unit($value, px) !important
			padding-right: unit($value, px) !important

		&_vertical_{$value}
			padding-top: unit($value, px) !important
			padding-bottom: unit($value, px) !important

		for $direction in $offsets.directions
			&_{$direction}_{$value}
				padding-{$direction}: unit($value, px) !important
