@import "../../styles/vars.styl"
for $value in $offsets.values
	.margin
		&_{$value}
			margin: unit($value, px) !important

		&_horizontal_{$value}
			margin-left: unit($value, px) !important
			margin-right: unit($value, px) !important

		&_vertical_{$value}
			margin-top: unit($value, px) !important
			margin-bottom: unit($value, px) !important

		for $direction in $offsets.directions
			&_{$direction}_{$value}
				margin-{$direction}: unit($value, px) !important
