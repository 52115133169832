.layout
	position: relative
	display: flex;

	&__side
		width: $layout-side-width
		height: 100%
		position: fixed
		z-index: 50;
		transition: transform .3s ease

		&_opened
			transform: translateX(0);
			width: initial;
			right: 40px;
			bottom: 0;

			.layout__side-hider
				background-color: $colors.background

			.layout__hider-icon
				transform: translate(50%,-50%) rotate(180deg);

			.layout__burger-icon
				display: none

	&__side-inner
		height: 100%
		background: $colors.sidebar

		position: relative
		z-index: 10
		display: flex;
	&__burger-icon
		color: white;
		position: absolute;
		transform: translateX(-50%);
		left: 50%;
		top: 16px;

	&__bar
		height: 100%
		z-index: 4;
		position: relative;

	&__inner
		position: relative
		min-height: 100vh
		width: 100%
		flex: 1;

	&__content
		z-index: 20
		position: relative
		height: 100%
		width: calc(100% - 430px);
		margin-left: $layout-side-width;
		overflow-x hidden;
		min-height 100vh

		&_faded
			&:after
				content: '';
				height: 100%;
				width: 100%;
				background-color: rgba(0,0,0,0.9);
				position: fixed;
				z-index: 12;
				display: block;
				top: 0;
				left: 0;


		&_slided
			transform: translateX(100%)
