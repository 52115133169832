@import "../../styles/vars.styl"
@import "../../styles/helpers.styl"

html,
body
	margin: 0

body
	position: relative

body
	font-family: 'Open Sans', sans-serif
	font-set: '16-light'
	overflow: auto
	-webkit-overflow-scrolling: touch

#app
	height: 100%
