@import "../../styles/vars.styl"
.main-form
	height: 100%
	margin-bottom: 26px;
	z-index: 4;
	position: relative;

	&__scroll
		border-left 1px solid rgba(255,255,255,0.2)	

	&__wrapper
		position: relative;

		&_extended
			/* 200 px is an approximate height of filter's popup*/
			min-height: calc(100vh + 200px);

	&__fields
		padding: 9px 24px

		@media screen and (max-width: 768px)
			padding: 0 18px 6px

	&__inner
		height: 100%

	&__top
		overflow: hidden

	&__tabs
		display: flex;
		justify-content: space-between;

	&__input
		margin-bottom: 5px;
		align-self: flex-end;
		position: relative;

		&_mobile-active
			position: fixed;
			top: 9px;
			z-index: 23;
			left: 49px;
			max-width: 80%;

	&__inputs
		margin-bottom: 20px;

	&__travelers
		margin-bottom: 25px;

		.switcher
			position: relative;
			top: 4px;
			left: 3px;
			display: flex;
			justify-content: start;

	&__travel-type
		padding-top: 20px;

		@media screen and (max-width: 768px)
			padding-top: 4px;

			.switcher__content
				opacity: 0.5;

	&__tab
		padding: 19px 24px 14px;

		box-sizing: border-box;
		font-size: 15px
		transition: color .2s ease-in-out, background .2s ease-in-out
		display: inline-block
		color: rgba(#fff, .5)
		cursor: pointer
		position: relative
		width: calc(50% - 1px);
		background-color: rgba(#2e2536, 1)

		&:last-child:after
			content: ''
			position: absolute
			top: 0
			bottom: 0
			left: 100%
			width: 1000px
			background: rgba(#41272f, .5)
			transition: color .2s ease-in-out, background .2s ease-in-out

		&:hover
			color: #fff

		&_active
			background-color: #3f293a
			&, &:last-child:after, &:hover, &:hover:last-child:after
				color: #fff
				cursor: default
				background-color: transparent

		@media screen and (min-width: 769px) and (max-width: 800px), screen and (min-width: 320px) and (max-width: 429px)
			padding-left: 10px

	&__collapse
		position: absolute
		right: 20px
		top: 8px
		display: none

	&__reset
		position: absolute
		left: 0
		bottom: 0
		padding: 10px 10px
		right: 15px;
		background-color: #3f293a

	&__mobile-content
		flex: 1;
		overflow: auto;
		min-width: 100%;
		background-color:#4b76ff;