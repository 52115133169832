@import "../../styles/vars.styl"
.overlay
	overflow-x: auto
	overflow-y: scroll
	position: fixed
	z-index: 2000
	top: 0
	right: 0
	bottom: 0
	left: 0
	height: 100%
	background: rgba(22, 22, 30, .9)

	&_small
		width: calc(100% - 430px)
		left: 430px

	&__box
		display: table
		width: 100%
		height: @width

	&__cell
		display: table-cell
		width: 100%
		height: @width
		text-align: center
		vertical-align: middle

	&__inner
		white-space: nowrap
		text-align: center
		margin: 44px 0

	@media (max-width: 580px)
		&__inner
			margin: 0

	&__content
		display: inline-block
		vertical-align: top
		z-index: 1499
		text-align: left
		white-space: normal
		margin: 0 auto

	&__loader
		text-align: center
		display: none

	&_loading
		.overlay
			&__content
				display: none

			&__loader
				display: block
